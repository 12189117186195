import { get } from 'lodash'

export default {
  methods: {
    __(key, replace = {}) {
      let translation = get(this.$page.props.translations, key, key)

      Object.keys(replace).forEach(function (key) {
        translation = translation.replace(':' + key, replace[key])
      })

      return translation
    }
  }
}
