import Vue from 'vue'
import VueMoment from 'vue-moment'
import VueApexCharts from 'vue-apexcharts'
import VueToast from 'vue-toastification'
import VuetifyDialog from 'vuetify-dialog'
import VuetifyForm from 'vuetify-form'
import moment from 'moment'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/vi.json'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { createInertiaApp, Link, Head } from '@inertiajs/inertia-vue'
import { InertiaProgress } from '@inertiajs/progress'
import VLink from '@/components/VLink'
import axios from 'axios'
import translation from '@/mixins/translation'
import vuetify from '@/plugins/vuetify'
import store from '@/store'

import 'vue-toastification/dist/index.css'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import '@/assets/tailwind.css'
import '@/scss/theme.scss'
import '@/filters/product'
import '@/filters/format-date'
import '@/filters/customer'

Vue.config.productionTip = false

require('moment/locale/vi')

Vue.use(VueApexCharts)
Vue.use(VueToast)
Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
})
Vue.use(VuetifyForm)
Vue.use(VueMoment, {
  moment
})

/* eslint-disable */
Object.keys(rules).forEach((item) => {
  extend(item, {
    ...rules[item],
    message: messages[item]
  })
})
/* eslint-enable */

Vue.component('Apexchart', VueApexCharts)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('VuePerfectScrollbar', VuePerfectScrollbar)
Vue.component('Head', Head)
Vue.component('Link', Link)
Vue.component('VLink', VLink)

Vue.prototype.$route = window.route
Vue.prototype.$axios = axios

InertiaProgress.init()

createInertiaApp({
  resolve: (name) => import('./pages/' + name),
  setup({ el, App, props, plugin }) {
    Vue.use(plugin)
    Vue.mixin(translation)

    new Vue({
      vuetify,
      store,
      render: (h) => h(App, props)
    }).$mount(el)
  }
})
