import theme from './theme'

export default {
  // product display information
  product: {
    name: 'Abio Việt Nam',
    version: '0.1.0'
  },

  // theme configs
  theme
}
