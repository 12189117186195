import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import en from 'vuetify/lib/locale/vi'
import config from '@/configs'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    current: 'en',
    locales: { en }
  },
  theme: {
    dark: config.theme.globalTheme === 'dark',
    options: {
      customProperties: true
    },
    themes: {
      light: config.theme.light,
      dark: config.theme.dark
    }
  }
})
