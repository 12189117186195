import configs from '@/configs'

const { product, theme } = configs
const { globalTheme, toolbarTheme, menuTheme, isToolbarDetached, isContentBoxed } = theme

const state = {
  product,

  // themes and layout configurations
  globalTheme,
  toolbarTheme,
  menuTheme,
  isToolbarDetached,
  isContentBoxed
}

const getters = {}

const actions = {}

const mutations = {
  setGlobalTheme: (state, theme) => {
    this.$vuetify.framework.theme.dark = theme === 'dark'
    state.globalTheme = theme
  },
  setToolbarTheme: (state, theme) => {
    state.toolbarTheme = theme
  },
  setMenuTheme: (state, theme) => {
    state.menuTheme = theme
  },
  setToolbarDetached: (state, isDetached) => {
    state.isToolbarDetached = isDetached
  },
  setContentBoxed: (state, isBoxed) => {
    state.isContentBoxed = isBoxed
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
